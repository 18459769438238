<template>
 <div>
    <div class="wrapper">
         <header class="header">
            <div class="header__outer inner">
                <h1 @click="goHome" class="header__title">Home</h1>
                <div class="header__inner" @click="showInfo">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0)">
                                                        <path d="M10.5 7.125C10.5 6.507 11.007 6 11.625 6H12.375C12.993 6 13.5 6.507 13.5 7.125V7.875C13.5 8.493 12.993 9 12.375 9H11.625C11.327 8.99882 11.0415 8.87991 10.8308 8.66919C10.6201 8.45846 10.5012 8.173 10.5 7.875V7.125Z" fill="url(#paint0_linear)"/>
                                                        <path d="M15 18H9V16.5H10.5V12H9V10.5H13.5V16.5H15V18Z" fill="url(#paint1_linear)"/>
                                                        <path d="M12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0V0ZM12 21.75C9.41414 21.75 6.93419 20.7228 5.10571 18.8943C3.27723 17.0658 2.25 14.5859 2.25 12C2.25 9.41414 3.27723 6.93419 5.10571 5.10571C6.93419 3.27723 9.41414 2.25 12 2.25C14.5859 2.25 17.0658 3.27723 18.8943 5.10571C20.7228 6.93419 21.75 9.41414 21.75 12C21.75 14.5859 20.7228 17.0658 18.8943 18.8943C17.0658 20.7228 14.5859 21.75 12 21.75Z" fill="url(#paint2_linear)"/>
                                                        </g>
                                                        <defs>
                                                        <linearGradient id="paint0_linear" x1="9.9" y1="7.5" x2="14.05" y2="7.5" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.0697675" stop-color="#A07DEA"/>
                                                        <stop offset="1" stop-color="#5F4BAE"/>
                                                        </linearGradient>
                                                        <linearGradient id="paint1_linear" x1="7.8" y1="14.25" x2="16.1" y2="14.25" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.0697675" stop-color="#A07DEA"/>
                                                        <stop offset="1" stop-color="#5F4BAE"/>
                                                        </linearGradient>
                                                        <linearGradient id="paint2_linear" x1="-4.8" y1="12" x2="28.4" y2="12" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.0697675" stop-color="#A07DEA"/>
                                                        <stop offset="1" stop-color="#5F4BAE"/>
                                                        </linearGradient>
                                                        <clipPath id="clip0">
                                                        <rect width="24" height="24" fill="white"/>
                                                        </clipPath>
                                                        </defs>
                                </svg>

                </div>
            </div>
        </header>
        <div class="container">
            <h2 class="choose_title">Choose photo below</h2>
            <div id="images" class="row" >
                <div class='column' v-for="item in images" :key="item"> 
                    <router-link :to='"./playGame?img_url="+item'>
                        <img :src='item' alt='Snow' style='width:100%'>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <div class="info" v-show="info">
        <h3 class="infotitle">About</h3>
        <span class="infoclose"  @click="closeInfo"></span>
        <p><b>Version: </b>1</p>
        <p><b>Developer: </b>Jesperpus Games</p>
    </div>
    <div class="back" v-show="info"></div>
 </div>
</template>

<script>
export default {
     name:'Choose',
      data(){
            return{
                images:[],
                info:false
            }
        },
    created(){
        //this.getAllImages();
         this.$store.state.images.forEach(element => {
                                    this.images.push(element.image);
                                });

        if(this.images.length == 0){
            this.getAllImages();
        }
    },
    computed:{
        webShareApiSupported() {
            return navigator.share
        }
    },
    methods:{
        showInfo(){
            this.info = true;
        },
        closeInfo(){
            this.info = false;
        },
        shareViaWebShare() {

            this.$store.dispatch("addPuzzleShareStats","imageURL")
						.then(response =>{
								

                        }) .catch(error => {
              });


                navigator.share({
                    title: 'Title to be shared',
                    text: 'Text to be shared',
                    url: 'URL to be shared'
                })

                


        },
        getAllImages(){
            

            this.$store.dispatch("getPuzzleImages")
						.then(response =>{
                                 response.data.forEach(element => {
                                    this.images.push(element.image);
                                });

                        }) 
        },
     /*   getAllImages(){
             var folder = "/uploads_Puzzle/";

            this.$store.dispatch("getPuzzleImages")
						.then(response =>{

                           response.data.forEach(element => {
                                    this.images.push(element.image);
                                });
                        }) 
        },*/
        /* getAllImages(){
             var folder = "/uploads_Puzzle/";
             var that = this;

                    $.ajax({
                            url : folder,
                            success: function (data) {

                               
                                $(data).find("a").attr("href", function (i, val) {

                                    if( val.match(/\.(jpe?g|png|gif)$/) ) { 

                                        that.images.push(val);
/*
                                        $("#images").append( "<div class='column'> <a href='/playGame?img_url="+val+"'>  <img src='"+ val +"' alt='Snow' style='width:100%'></a></div>" );
                                    */
/*                                    } 

                                });
                            }
                        });
                    },*/
                goHome(){
                    this.$router.push({ path: '/' })
                }
    }

}

            
        
</script>